<template>
    <h1>Sample Packs work in progress...</h1>
</template>

<script>
export default {
  name: 'PageSamplePacks'
}
</script>


<style lang="stylus" scoped>
</style>